.navbar{
  height: 90px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.logo-container{
  text-align: end;
}

.navbar-brand{
  margin-left: var(--bs-navbar-brand-margin-end);
}
/* Logo font */
#logo {
    font-family: 'Gloock', serif;
}

/* Menu font */
.menu {
    font-family: 'Cormorant Garamond', serif;
}

.navbar-toggler{
    border: none;
}

.navbar-toggler:focus{
    outline: none;
}

.offcanvas-header{
  height:90px;
}

.offcanvas-header .btn-close{
  /* margin-top: 0px; */
  margin-right: 0px;
}

.offcanvas.offcanvas-top {
  height: 100vh;
}


