.main{
    margin-top:90px;
    display: flex;
    flex-direction: column;

}

h2{
    font-family: 'Gloock', serif;
    margin: 74px 0px 36px 0px;
    font-size: 35px;
}


h3{
    font-family: "Gloock", serif;
    margin: 30px 0px 36px 0px;
    font-size: 20px;
}

h4{
    font-weight: 700;
    font-size: 17px;
}

/* Section1: Main */
.main-image-container {
    position: relative;
}

.overlay{
    position: absolute;
    color: #fff;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center; /* 수평 정렬 */
    align-items: center; /* 수직 정렬 */
    text-align: center; /* 수평 정렬 */
}

.overlay-container{
    position: absolute;
}

.overlay-title{
    font-size: 55px;
    font-weight: bold;
    margin: 0px 0px 28px 0px;
}

.overlay-button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overlay-button{
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 15px 85px;
    background-color: #fff;
}

.overlay-button{
    color: #33343F;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-decoration: none;
}


/* Section2: About Us */
.section2{
    background-color: rgb(51, 52, 63);
    overflow: hidden;
    color: #fff;
}

.section2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section2-text-container{
    justify-items: center;
}

.section2 p{
    font-size: 16px;
    line-height: 1.875em;
    text-align: center;
    letter-spacing: normal;
    padding: 20px;
}


/* Section3: Service */
.line-container{
    display: flex;
    justify-content: center;
}
.horizontal-line{
    width: 80%; 
    height: 2px;
    background-color: black; 
}

.section3.row>*{
    margin: 0px;
}

.section3 span{
    display: block;
    margin: 15px 0px 10px 0px;
}

.pricing-button{
    margin: 30px 0px 50px 0px;
    background-color: #fff;
    color: #33343F;
    letter-spacing: 0.1em;
    text-decoration: none;
}

.pricing-button a{
    color: #33343F;
    font-weight: 700;
    text-decoration: none;
    padding: 15px 85px;
    border: 1px solid #33343F;
}


/* Section4: How We Work */
.section4{
    overflow: hidden; 
    position: relative;
}
.section4 img{
    width: 100%;
    object-fit: cover;
        object-position: 50% 50% center;
}

.section4-container {
    position: relative;
}

.section4-overlay{
    color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center; /* 수평 정렬 */
    align-items: center; /* 수직 정렬 */
    text-align: center; /* 수평 정렬 */
}

.section4-text-container{
    justify-items: center;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: space-around;
}

/* Section5: Partner program */
.section5.row>*{
    margin: 0px;
}

.section5 .col{
    margin: 20px 0px 20px 0px;
}

.section5 .box-container{
    display: flex;
    justify-content: flex-end;
}

.section5 .box{
    background-color: #fff;
    padding: 10px;
    margin-right: 30px;
    width: 70%;
    text-align: left;
}

.section5 p{
    margin: 0px;
}
.gray-layout{
    background-color: black;
}

/* Section6: What Our Clients Say*/
.section6 {
    background-image: url(../assets/MainSection6.jpg);
    display: flex;
    justify-content: center; /* 수평 정렬 */
    align-items: center; /* 수직 정렬 */
    text-align: center; /* 수평 정렬 */
}

.section6 .text-container .text-name{
    font-weight: bold;
    margin: 10px 0px 50px 0px;
}

/* Section7: Contact Us */


@media only screen and (max-width: 1200px) {
    .overlay-container {
        position: absolute;
    }
    
    .overlay-title{
        font-size: 50px;
        font-weight: bold;
    }
}

@media only screen and (max-width: 992px) {
    .overlay-title{
        font-size: 30px;
        font-weight: bold;
    }
}

@media only screen and (max-width: 768px) {
    .row{
        flex-direction: column;
    }

    h2{
        margin: 28px 0px 28px 0;
        font-size: 20px;
    }

    h3{
        margin: 15px 0px 10px 0px;
        font-size: 18px;
    }

    h4{
        margin: 15px 0px 30px 0px;
        font-size: 14px;
        line-height: 2.5em;
    }

    p{
        font-size: 14px;
    }

    span{
        
        font-size: 13px;
    }

    /* Section1: Main */
    #main-image{
        object-fit: cover;
        min-height: 280px;
        
    }

    .overlay-container h1{
        font-size: 20px;
    }

    .overlay-container {
        position: absolute;
    }

    .overlay-button{
        padding: 8px 22px;
    }

    .overlay-button{
        font-size: 13px;
    }

    .section2 img{
        max-height: 260px;
        object-fit: cover;
    }

    /* Section3: Service */
    .pricing-button a{
        padding: 8px 22px;
    }

    /* Section5: Partner program */
    .section5 .title{
        font-size: 18px;
    }
    
    .section5 img{
        width: 70%;
        object-fit: cover;
        object-position: 50% 50%;
    }

    

    /* Section6: What our client Say */
    .section6{
        height: 350px;
        background-position: right 30% center; 
    }
}