footer{
    background-color: #2F2E2E;
}

footer #logo{
    font-size: 22px;
    margin-top: 5%;
    margin-bottom: 5%;
}

footer #copyright{
    margin-top: 5%;
}

footer p{
    color: #fff;
    line-height: 1.79em;
    text-align: center;
    font-size: 14px;
}